<template>
  <div>
    <b-row>
      <b-col
        v-for="(integration, index) in integrations"
        :key="index"
        lg="3"
        class="text-center"
      >
        <b-card
          :title="integration.image ? '' : integration.title"
          :img-src="require('@/assets/images/integrations/' + integration.image)"
          class="integration p-2"
        >
          <b-button
            variant="primary"
            :to="{ name: 'edit-integration', params: { integrationName: integration.name }}"
          >View
            integration
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
  },
  data() {
    return {
      integrations: [
        {
          name: 'mailchimp',
          title: 'Mailchimp',
          image: 'mailchimp.svg',
        },
        {
          name: 'planning-center',
          title: 'Planning Center',
          image: 'planning-center.svg',
        },
      ],
    }
  },
}
</script>

<style scoped>
  .custom-control {
    min-height: 1.7rem
  }
  .integration .card-img {
    height: 50px;
    margin: auto;
    max-width: 250px;
    object-fit: contain;
    padding: 0 5px;
    width: 100%;
  }
</style>
